<template>
  <div style="min-height:10vh">
    <div class="top-bar">
      <titleLink title="位置详情" :onClick="onClickBack"></titleLink>
    </div>
    <amap cache-key="home-map"
      map-style="amap://styles/whitesmoke"
      :zoom="15" ref="map"
      :center="location" style="height:100vh;z-index:-9999;position:fixed;bottom:0">
      <amap-info-window is-custom v-if="showInfoWindow"
        :position="location" :visible="true" :offset="[0,-20]"
        >
        <card>
          <template #content>
            <van-row>
              <van-col span="24" class="title">
                {{name}}
              </van-col>
              <van-col span="24" class="info_content" v-if="tel != '' || tel.length != 0">
                电话：{{tel}}
              </van-col>
              <van-col span="24" class="info_content">
                地址：{{addr}}
              </van-col>
            </van-row>
            <van-row style="margin-top:.2rem">
              <van-col span="12" class="left pointer">
                <van-icon :name="tel == ''? 'phone-o': 'phone-circle'" @click="call" size="40" 
                  :color="tel == ''? 'grey': 'lightgreen'"/>
              </van-col>
              <van-col span="12" class="right pointer">
                <van-icon name="guide-o" size="40" color="lightgreen" @click="guide"/>
              </van-col>
            </van-row>
          </template>
        </card>
      </amap-info-window>
      <amap-marker :position="location">
      </amap-marker>
    </amap>
    <van-button type="info" class="map_btn" round v-if="!showInfoWindow" @click="openGuideApp">打开导航</van-button>
    <van-action-sheet
      v-model="showPhonePick"
      :actions="phoneList"
      cancel-text="取消"
      close-on-click-action
      @cancel="showPhonePick = false"
      @select="selectCall"
    />
  </div>
</template>

<script>
import card from "@/views/components/Card";
import titleLink from "@/views/components/TitleLink"
import {Dialog} from "vant"
export default {
  components: {
    card, titleLink
  },
  data() {
    return {
      location: [],
      locationStr: "",
      name: "",
      tel: "",
      addr: "",
      current_location: "",
      cLocation: [],
      showInfoWindow: true,
      route: [],
      isBlured: false,
      isiOS: false,
      phoneList: [],
      showPhonePick: false
    }
  },
  mounted() {
    const query = this.$route.query 
    this.locationStr = query.location
    this.cLocation = query.current_location.split(',')
    this.location = query.location.split(',')
    this.name = query.name
    this.tel = query.tel
    this.current_location = query.current_location,
    this.addr = query.addr
    window.addEventListener('blur', this.blur, false)
  },
  destroyed() {
    window.removeEventListener('blur', this.blur, false)
  },
  methods: {
    onClickBack() {
      this.$router.push("/xinxi/main");
    },
    blur() {
      this.isBlured = true
    },
    call() {
      if(this.tel != '') {
        if(this.tel.indexOf(';') < 0) {
          window.location.href = `tel://${this.tel}`
        } else {
          this.phoneList = []
          this.tel.split(';').forEach(ele => {
            this.phoneList.push({name: ele})
          })
          this.showPhonePick = true
        }
      }
    },
    selectCall(val) {
      window.location.href = `tel://${val.name}`
    },
    guide() {
      const _self = this
      this.showInfoWindow = false
      AMap.plugin('AMap.Driving', function() {
        var driving = new AMap.Driving({
          // 驾车路线规划策略，AMap.DrivingPolicy.LEAST_TIME是最快捷模式
          policy: AMap.DrivingPolicy.LEAST_TIME,
          map: _self.$refs.map.$map,
          showTraffic: true,
          isOutline: true,
          autoFitView: true
        })
        var startLngLat = _self.current_location.split(',')
        var endLngLat = _self.location
        
        driving.search(startLngLat, endLngLat, function (status, result) {
          console.log(result);
        })
      })
    },
    openGuideApp() {
      this.showInfoWindow = false
      var u = navigator.userAgent;//判断是android系统还是IOS系统
      var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
      this.isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      console.log(u);
      if(isAndroid) {
        var url = `https://m.amap.com/navigation/carmap/saddr=${this.current_location},${'当前地点'}&daddr=${this.locationStr},${this.name}&saddr_lonlat=${this.current_location}&daddr_lonlat=${this.locationStr}`
        // var url = `androidamap://route?sourceApplication=amap&poiname=${this.name}&dlat=${this.location[1]}&dlon=${this.location[0]}`
        // this.testApp(url, "https://wap.amap.com/?from=m&type=m")
        window.location = url
      }
      if(this.isiOS) {
        var url = `http://maps.apple.com/?q=${this.name}&sll=${this.locationStr}&z=10&t=s`
        // this.testApp(url, "https://itunes.apple.com/app/id915056765?ls=1&mt=8")
        window.location = url
      }
    },
    // testApp(url, downUrl) {
    //   const _self = this
    //   setTimeout(function(){
    //     if(!_self.isBlured && !_self.isiOS) {
    //       _self.confirmDownload(downUrl)
    //     }
    //   },1000);
    //   window.location = url//打开某手机上的某个app应用
    // },
    confirmDownload(downUrl) {
      Dialog.confirm({
        message: "您的手机暂未安装导航，是否跳转到下载页？"
      }).then(() => {
        window.location.replace(downUrl)//如果超时就跳转到app下载页
      }).catch(() => {
        console.log('cancel download')
      })
    }
  }
}
</script>

<style scoped>
.title {
  font-size: 15px;
  font-weight: bold;
}
.info_content {
  font-size: 12px;
  color: #666666;
}
.left {
  text-align: left;
}
.right {
  text-align: right;
}
.pointer {
  cursor: pointer;
}
.top-bar {
  position: fixed;
  top: 0;
  width: 100%;
  height: 1.2rem;
  z-index: 99;
  background-image: url('../../assets/images/bg.jpg');
}
.map_btn {
  z-index: 9999;
  position: fixed;
  bottom: .3rem;
  width: 40vw;
  margin-left: 30vw;
}
</style>